"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * @since 2019-12-27 01:31
 * @author vivaxy
 */
function concatUInt8Array(a, b) {
    const concated = new Uint8Array(a.length + b.length);
    concated.set(a);
    concated.set(b, a.length);
    return concated;
}
exports.concatUInt8Array = concatUInt8Array;
