"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * @since 2019-12-27 01:29
 * @author vivaxy
 */
exports.FILTER_LENGTH = 1;
var FILTER_TYPES;
(function (FILTER_TYPES) {
    FILTER_TYPES[FILTER_TYPES["NONE"] = 0] = "NONE";
    FILTER_TYPES[FILTER_TYPES["SUB"] = 1] = "SUB";
    FILTER_TYPES[FILTER_TYPES["UP"] = 2] = "UP";
    FILTER_TYPES[FILTER_TYPES["AVERAGE"] = 3] = "AVERAGE";
    FILTER_TYPES[FILTER_TYPES["PAETH"] = 4] = "PAETH";
})(FILTER_TYPES = exports.FILTER_TYPES || (exports.FILTER_TYPES = {}));
exports.unfilters = {
    [FILTER_TYPES.NONE](data) {
        return data;
    },
    [FILTER_TYPES.SUB](data, bytePerPixel) {
        const unfiltered = new Uint8Array(data.length);
        for (let i = 0; i < data.length; i++) {
            const left = unfiltered[i - bytePerPixel] || 0;
            unfiltered[i] = data[i] + left;
        }
        return unfiltered;
    },
    [FILTER_TYPES.UP](data, bytePerPixel, prevUnfilteredLine) {
        const unfilteredLine = new Uint8Array(data.length);
        for (let i = 0; i < data.length; i++) {
            const up = prevUnfilteredLine[i] || 0;
            unfilteredLine[i] = up + data[i];
        }
        return unfilteredLine;
    },
    [FILTER_TYPES.AVERAGE](data, bytePerPixel, prevUnfilteredLine) {
        const unfilteredLine = new Uint8Array(data.length);
        for (let i = 0; i < data.length; i++) {
            const left = unfilteredLine[i - bytePerPixel] || 0;
            const above = prevUnfilteredLine[i] || 0;
            const avg = (left + above) >> 1;
            unfilteredLine[i] = data[i] + avg;
        }
        return unfilteredLine;
    },
    [FILTER_TYPES.PAETH](data, bytePerPixel, prevUnfilteredLine) {
        const unfilteredLine = new Uint8Array(data.length);
        for (let i = 0; i < data.length; i++) {
            const left = unfilteredLine[i - bytePerPixel] || 0;
            const above = prevUnfilteredLine[i];
            const upperLeft = prevUnfilteredLine[i - bytePerPixel] || 0;
            const p = paeth(left, above, upperLeft);
            unfilteredLine[i] = data[i] + p;
        }
        return unfilteredLine;
    },
};
exports.filters = {
    [FILTER_TYPES.NONE](data) {
        let sum = 0;
        for (let i = 0; i < data.length; i++) {
            sum += data[i];
        }
        return {
            sum,
            filtered: data,
        };
    },
    [FILTER_TYPES.SUB](data, bytePerPixel) {
        let sum = 0;
        const filtered = new Uint8Array(data.length);
        for (let i = 0; i < data.length; i++) {
            const left = data[i - bytePerPixel] || 0;
            filtered[i] = data[i] - left;
            sum += filtered[i];
        }
        return {
            sum,
            filtered,
        };
    },
    [FILTER_TYPES.UP](data, bytePerPixel, prevUnfilteredLine) {
        let sum = 0;
        const filtered = new Uint8Array(data.length);
        for (let i = 0; i < data.length; i++) {
            const up = prevUnfilteredLine[i] || 0;
            filtered[i] = data[i] - up;
            sum += filtered[i];
        }
        return {
            sum,
            filtered,
        };
    },
    [FILTER_TYPES.AVERAGE](data, bytePerPixel, prevUnfilteredLine) {
        let sum = 0;
        const filtered = new Uint8Array(data.length);
        for (let i = 0; i < data.length; i++) {
            const left = data[i - bytePerPixel] || 0;
            const above = prevUnfilteredLine[i] || 0;
            const avg = (left + above) >> 1;
            filtered[i] = data[i] - avg;
            sum += filtered[i];
        }
        return {
            sum,
            filtered,
        };
    },
    [FILTER_TYPES.PAETH](data, bytePerPixel, prevUnfilteredLine) {
        let sum = 0;
        const filtered = new Uint8Array(data.length);
        for (let i = 0; i < data.length; i++) {
            const left = data[i - bytePerPixel] || 0;
            const above = prevUnfilteredLine[i] || 0;
            const upperLeft = prevUnfilteredLine[i - bytePerPixel] || 0;
            const p = paeth(left, above, upperLeft);
            filtered[i] = data[i] - p;
            sum += filtered[i];
        }
        return {
            sum,
            filtered,
        };
    },
};
function paeth(left, above, upperLeft) {
    const p = left + above - upperLeft;
    const pa = Math.abs(p - left);
    const pb = Math.abs(p - above);
    const pc = Math.abs(p - upperLeft);
    if (pa <= pb && pa <= pc) {
        return left;
    }
    else if (pb <= pc) {
        return above;
    }
    else {
        return upperLeft;
    }
}
