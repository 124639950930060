import { NativeModulesProxy, EventEmitter, Subscription } from 'expo-modules-core';

// Import the native module. On web, it will be resolved to ExpoCpPrint.web.ts
// and on native platforms to ExpoCpPrint.ts
import ExpoCpPrintMainModule from './ExpoCpPrintMainModule';
import ExpoCpPrintEvolisModule from './ExpoCpPrintEvolisModule';
import ExpoCpPrintZebraModule from './ExpoCpPrintZebraModule';
// import ExpoCpPrintView from './ExpoCpPrintView';
import { ChangeEventPayload, ExpoCpPrintViewProps } from './ExpoCpPrint.types';

type manufacturer = 'EVOLIS' | 'ZEBRA';
var activePrintModule: any = ExpoCpPrintEvolisModule;

// Get the native constant value.
export const PI = activePrintModule.PI;

export async function hello(): Promise<string> {
  return await activePrintModule.hello();
}

export async function setManufacturer(manufacturer: manufacturer): Promise<boolean> {
  try {

    if (manufacturer === null){
      manufacturer = "EVOLIS"
    }

    switch (manufacturer.toUpperCase()) {
      case 'EVOLIS':
        activePrintModule = ExpoCpPrintEvolisModule;
        return true;
        break;

      case 'ZEBRA':        
        activePrintModule = ExpoCpPrintZebraModule;
        return true;
        break;
    
      default:
        break;
    }
    return true;

  } catch (e: any) {
    console.log(e);
    return false;
  }
}

export async function avaliableManufacturers(): Promise<[]> {
  try {
    return await ExpoCpPrintMainModule.avaliableManufacturers();    
  } catch (e: any) {
    console.log(e);
    return [];
  }
}

export async function getAvaliableOperations(): Promise<[] | boolean > {
  // export async function listPrinters(): string {
  try {
    return await activePrintModule.getAvaliableOperations();
  } catch (e: any) {
    console.log(e);
    return false;
  }
}

export async function connectToPrinter(printerIpAddress: string): Promise<boolean> {
  // export async function listPrinters(): string {
  try {
    return await activePrintModule.connectToPrinter(printerIpAddress);
  } catch (e: any) {
    console.log(e);
    return false;
  }
}

export async function listPrinters(inputText: string): Promise<String> {
  // export async function listPrinters(): string {
  try {
    return await activePrintModule.listPrinters(inputText);
  } catch (e: any) {
    console.log(e);
    return "Error";
  }
}

export async function restartPrinter(): Promise<boolean> {
  try {
    return await activePrintModule.restartPrinter();
  } catch (e: any) {
    console.log(e);
    return false;
  }
}

export async function ejectCard(): Promise<boolean> {
  try {
    return await activePrintModule.ejectCard();
  } catch (e: any) {
    console.log(e);
    return false;
  }
}

export async function rejectCard(): Promise<boolean> {
  try {
    return await activePrintModule.rejectCard();
  } catch (e: any) {
    console.log(e);
    return false;
  }
}

export async function insertCard(): Promise<boolean> {
  try {
    return await activePrintModule.insertCard();
  } catch (e: any) {
    console.log(e);
    return false;
  }
}
export async function insertEjectCard(): Promise<boolean> {
  try {
    return await activePrintModule.insertEjectCard();
  } catch (e: any) {
    console.log(e);
    return false;
  }
}

export async function getPrinterInfo(printerIpAddress: string | null = null): Promise<any | boolean> {
  try {
    return await activePrintModule.getPrinterInfo(printerIpAddress);
  } catch (e: any) {
    console.log(e);
    return false;
  }
}

export async function newPrintJob(cardBase64Data: string, ribbonType: string, printResolution: string = "DPI300", cardBase64BackData: string = "", orientation: string = "LANDSCAPE"): Promise<string | boolean> {
  try {
    return await activePrintModule.newPrintJob(cardBase64Data, ribbonType, printResolution, cardBase64BackData, orientation);
  } catch (e: any) {
    console.log(e);
    return false;
  }
}

export async function setValueAsync(value: string) {
  return await activePrintModule.setValueAsync(value);
}

const emitter = new EventEmitter(activePrintModule ?? NativeModulesProxy.ExpoCpPrint);

export function addChangeListener(listener: (event: ChangeEventPayload) => void): Subscription {
  return emitter.addListener<ChangeEventPayload>('onChange', listener);
}

export { ChangeEventPayload };
// export { ExpoCpPrintView, ExpoCpPrintViewProps, ChangeEventPayload };
