"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * @since 2019-10-30 05:14
 * @author vivaxy
 */
const crcTable = [];
for (let i = 0; i < 256; i++) {
    let currentCrc = i;
    for (let j = 0; j < 8; j++) {
        if (currentCrc & 1) {
            currentCrc = 0xedb88320 ^ (currentCrc >>> 1);
        }
        else {
            currentCrc = currentCrc >>> 1;
        }
    }
    crcTable[i] = currentCrc;
}
function crc32(buf) {
    let crc = -1;
    for (let i = 0; i < buf.length; i++) {
        crc = crcTable[(crc ^ buf[i]) & 0xff] ^ (crc >>> 8);
    }
    return crc ^ -1;
}
exports.default = crc32;
