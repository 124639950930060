"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * @since 2019-12-30 01:47
 * @author vivaxy
 */
const pako = require("pako");
const rescale_sample_1 = require("../helpers/rescale-sample");
const typed_array_1 = require("../helpers/typed-array");
const channels_1 = require("../helpers/channels");
const interlace_1 = require("../helpers/interlace");
const filters_1 = require("../helpers/filters");
const color_types_1 = require("../helpers/color-types");
function encodeIDAT(data, width, height, colorType, depth, interlace, palette) {
    let typedArray = new Uint8Array();
    const channelPerPixel = color_types_1.COLOR_TYPES_TO_CHANNEL_PER_PIXEL[colorType];
    const bitPerPixel = channelPerPixel * depth;
    const bytePerPixel = bitPerPixel >> 3 || 1;
    const images = interlace_1.buildImages(interlace, width, height);
    for (let imageIndex = 0; imageIndex < images.length; imageIndex++) {
        let prevUnfilteredLine = new Uint8Array();
        const { passWidth, passHeight, passIndex } = images[imageIndex];
        const scanlineWidth = ((passWidth * channelPerPixel * depth + 7) >> 3) + filters_1.FILTER_LENGTH;
        for (let heightIndex = 0; heightIndex < passHeight; heightIndex++) {
            // each line
            const channels = [];
            for (let widthIndex = 0; widthIndex < passWidth; widthIndex++) {
                // each pixel
                const pixelIndex = interlace_1.getPixelIndex(interlace, width, passIndex, widthIndex, heightIndex);
                const pixel = data.slice(pixelIndex, pixelIndex + 4);
                // channels
                if (colorType === color_types_1.COLOR_TYPES.GRAYSCALE) {
                    channels.push(rescale_sample_1.default(pixel[0], 8, depth));
                }
                else if (colorType === color_types_1.COLOR_TYPES.TRUE_COLOR) {
                    channels.push(rescale_sample_1.default(pixel[0], 8, depth), rescale_sample_1.default(pixel[1], 8, depth), rescale_sample_1.default(pixel[2], 8, depth));
                }
                else if (colorType === color_types_1.COLOR_TYPES.PALETTE) {
                    if (!palette) {
                        throw new Error('Palette is required');
                    }
                    let paletteIndex = -1;
                    for (let i = 0; i < palette.length; i++) {
                        const paletteColor = palette[i];
                        if (paletteColor[0] === pixel[0] &&
                            paletteColor[1] === pixel[1] &&
                            paletteColor[2] == pixel[2] &&
                            paletteColor[3] === pixel[3]) {
                            paletteIndex = i;
                        }
                    }
                    if (paletteIndex === -1) {
                        throw new Error('Invalid palette. Color (' +
                            pixel.join(', ') +
                            ') is not in palette');
                    }
                    channels.push(paletteIndex);
                }
                else if (colorType === color_types_1.COLOR_TYPES.GRAYSCALE_WITH_ALPHA) {
                    channels.push(rescale_sample_1.default(pixel[0], 8, depth), rescale_sample_1.default(pixel[3], 8, depth));
                }
                else if (colorType === color_types_1.COLOR_TYPES.TRUE_COLOR_WITH_ALPHA) {
                    channels.push(rescale_sample_1.default(pixel[0], 8, depth), rescale_sample_1.default(pixel[1], 8, depth), rescale_sample_1.default(pixel[2], 8, depth), rescale_sample_1.default(pixel[3], 8, depth));
                }
            }
            const unfilteredLine = channels_1.channelsToTypedArray(channels, depth, scanlineWidth - filters_1.FILTER_LENGTH);
            // filter line
            let minFilterSum = Infinity;
            let filteredLine = new Uint8Array();
            let filterType = 0;
            const filterTypes = [
                filters_1.FILTER_TYPES.NONE,
                filters_1.FILTER_TYPES.SUB,
                filters_1.FILTER_TYPES.UP,
                filters_1.FILTER_TYPES.AVERAGE,
                filters_1.FILTER_TYPES.PAETH,
            ];
            for (let filterIndex = 0; filterIndex < filterTypes.length; filterIndex++) {
                const currentFilterType = filterTypes[filterIndex];
                const { sum, filtered } = filters_1.filters[currentFilterType](unfilteredLine, bytePerPixel, prevUnfilteredLine);
                if (sum < minFilterSum) {
                    minFilterSum = sum;
                    filteredLine = filtered;
                    filterType = currentFilterType;
                }
            }
            prevUnfilteredLine = unfilteredLine;
            typedArray = typed_array_1.concatUInt8Array(typedArray, typed_array_1.concatUInt8Array(new Uint8Array([filterType]), filteredLine));
        } // end of each line
    }
    return pako.deflate(typedArray);
}
exports.default = encodeIDAT;
