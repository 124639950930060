"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * @since 2019-12-26 07:30
 * @author vivaxy
 */
const pako = require("pako");
const color_types_1 = require("../helpers/color-types");
const filters_1 = require("../helpers/filters");
const rescale_sample_1 = require("../helpers/rescale-sample");
const channels_1 = require("../helpers/channels");
const interlace_1 = require("../helpers/interlace");
function decodeIDAT(deflatedData, interlace, colorType, width, height, depth, palette, transparent) {
    let pixels = [];
    // inflate
    const inflatedData = pako.inflate(deflatedData);
    const images = interlace_1.buildImages(interlace, width, height);
    const channelPerPixel = color_types_1.COLOR_TYPES_TO_CHANNEL_PER_PIXEL[colorType];
    const bitPerPixel = channelPerPixel * depth;
    const bytePerPixel = bitPerPixel >> 3 || 1;
    let dataIndex = 0;
    let prevUnfilteredLine = new Uint8Array();
    for (let imageIndex = 0; imageIndex < images.length; imageIndex++) {
        const { passWidth, passHeight, passIndex } = images[imageIndex];
        for (let heightIndex = 0; heightIndex < passHeight; heightIndex++) {
            // scanline
            // const scanlineWidth = Math.ceil(metadata.width * channel * metadata.depth / 8) + FILTER_LENGTH;
            const scanlineWidth = ((passWidth * bitPerPixel + 7) >> 3) + filters_1.FILTER_LENGTH;
            // unfilter
            const filterType = inflatedData[dataIndex];
            if (!(filterType in filters_1.FILTER_TYPES)) {
                throw new Error('Unsupported filter type: ' + filterType);
            }
            const unfilter = filters_1.unfilters[filterType];
            const unfilteredLine = unfilter(inflatedData.slice(dataIndex + 1, dataIndex + scanlineWidth), bytePerPixel, prevUnfilteredLine);
            prevUnfilteredLine = unfilteredLine;
            // to channels
            let channelIndex = 0;
            const channels = channels_1.typedArrayToChannels(unfilteredLine, depth);
            function getPixelFromChannels() {
                if (colorType === color_types_1.COLOR_TYPES.GRAYSCALE) {
                    const color = rescale_sample_1.default(channels[channelIndex++], depth, 8);
                    return [color, color, color, 0xff];
                }
                if (colorType === color_types_1.COLOR_TYPES.TRUE_COLOR) {
                    return [
                        rescale_sample_1.default(channels[channelIndex++], depth, 8),
                        rescale_sample_1.default(channels[channelIndex++], depth, 8),
                        rescale_sample_1.default(channels[channelIndex++], depth, 8),
                        0xff,
                    ];
                }
                if (colorType === color_types_1.COLOR_TYPES.PALETTE) {
                    const paletteIndex = channels[channelIndex++];
                    if (!palette) {
                        throw new Error('Mising chunk: PLTE');
                    }
                    return palette[paletteIndex];
                }
                if (colorType === color_types_1.COLOR_TYPES.GRAYSCALE_WITH_ALPHA) {
                    const color = rescale_sample_1.default(channels[channelIndex++], depth, 8);
                    return [
                        color,
                        color,
                        color,
                        rescale_sample_1.default(channels[channelIndex++], depth, 8),
                    ];
                }
                if (colorType === color_types_1.COLOR_TYPES.TRUE_COLOR_WITH_ALPHA) {
                    return [
                        rescale_sample_1.default(channels[channelIndex++], depth, 8),
                        rescale_sample_1.default(channels[channelIndex++], depth, 8),
                        rescale_sample_1.default(channels[channelIndex++], depth, 8),
                        rescale_sample_1.default(channels[channelIndex++], depth, 8),
                    ];
                }
                throw new Error('Unsupported color type: ' + colorType);
            }
            for (let widthIndex = 0; widthIndex < passWidth; widthIndex++) {
                // to pixel
                const pixel = getPixelFromChannels();
                // process transparent color when available
                if (transparent &&
                    pixel[0] === transparent[0] &&
                    pixel[1] === transparent[1] &&
                    pixel[2] === transparent[2]) {
                    pixel[3] = 0;
                }
                const pixelIndex = interlace_1.getPixelIndex(interlace, width, passIndex, widthIndex, heightIndex);
                for (let i = 0; i < pixel.length; i++) {
                    pixels[pixelIndex + i] = pixel[i];
                }
            }
            dataIndex += scanlineWidth;
        }
    }
    return pixels;
}
exports.default = decodeIDAT;
