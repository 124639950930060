"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * @since 2019-12-27 01:25
 * @author vivaxy
 */
var COLOR_TYPES;
(function (COLOR_TYPES) {
    COLOR_TYPES[COLOR_TYPES["GRAYSCALE"] = 0] = "GRAYSCALE";
    COLOR_TYPES[COLOR_TYPES["TRUE_COLOR"] = 2] = "TRUE_COLOR";
    COLOR_TYPES[COLOR_TYPES["PALETTE"] = 3] = "PALETTE";
    COLOR_TYPES[COLOR_TYPES["GRAYSCALE_WITH_ALPHA"] = 4] = "GRAYSCALE_WITH_ALPHA";
    COLOR_TYPES[COLOR_TYPES["TRUE_COLOR_WITH_ALPHA"] = 6] = "TRUE_COLOR_WITH_ALPHA";
})(COLOR_TYPES = exports.COLOR_TYPES || (exports.COLOR_TYPES = {}));
exports.COLOR_TYPES_TO_CHANNEL_PER_PIXEL = {
    [COLOR_TYPES.GRAYSCALE]: 1,
    [COLOR_TYPES.TRUE_COLOR]: 3,
    [COLOR_TYPES.PALETTE]: 1,
    [COLOR_TYPES.GRAYSCALE_WITH_ALPHA]: 2,
    [COLOR_TYPES.TRUE_COLOR_WITH_ALPHA]: 4,
};
